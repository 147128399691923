import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import LanguageContext from "../../languageSwitch/LanguageContext";
import translations from "../../translations.json";
import "../css/BookButton.css";

const BookButton = () => {
    const location = useLocation();

    const { language } = useContext(LanguageContext);

    const [show, setShow] = useState(false);

    const handleMouseEnterButton = () => {
        setShow(true);
    };

    const handleMouseLeaveDropdown = () => {
        setShow(false);
    };

    return (
        <Dropdown
            as={ButtonGroup}
            show={show}
            style={{ position: "relative" }}
            onMouseLeave={handleMouseLeaveDropdown}
        >
            <div
                className="book_btn_toggle"
                id="dropdown-custom-components"
                onMouseEnter={handleMouseEnterButton}
            >
                <button style={{ border: "none", cursor: "pointer" }}>
                    <img src="/nav_bookBtn.png" alt="icon" />
                </button>
            </div>

            <div className="transparent-overlay"></div>

            <Dropdown.Menu>
                {location.pathname === "/" && (
                    <Dropdown.Item href="/platform">
                        {translations[language].learningPlatform}
                    </Dropdown.Item>
                )}
                <Dropdown.Item href="/home">
                    {translations[language].cancelOnlineSchool}
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default BookButton;
