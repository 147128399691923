import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LogoButton from "../button/components/LogoButton";
import MultipleGridContainer from "./components/MultipleGridContainer";
import Footer from "./components/Footer";
import { useDrag } from "react-dnd";
import { useLocation } from "react-router-dom";

import "./css/DaySchedule.css";

function DraggableVideo({ video }) {
    const [{ isDragging }, dragRef] = useDrag({
        type: "video",
        item: { video: JSON.stringify(video) },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    return (
        <button ref={dragRef} style={{ opacity: isDragging ? 0.5 : 1 }}>
            {video.name}
        </button>
    );
}

function DaySchedule() {
    const rowN = 4;
    const colN = 5;
    const [selectedContainer] = useState("MultipleGridContainer"); // 新增的state
    const location = useLocation();

    const renderSelectedContainer = () => {
        switch (selectedContainer) {
            case "MultipleGridContainer":
                return (
                    <MultipleGridContainer
                        gridData={gridData}
                        onDrop={onDrop}
                        onRemove={handleRemoveVideo}
                        numr={rowN}
                        numc={colN}
                    />
                );
            default:
                return null;
        }
    };

    const [videos /*, setVideos*/] = useState([
        { id: 1, name: "Course 1", path: "/第一課.mp4" },
        { id: 2, name: "Course 2", path: "/第二課.mp4" },
        { id: 3, name: "Course 3", path: "/第三課.mp4" },
        { id: 3, name: "Course 4", path: "/第三課.mp4" },
        { id: 3, name: "Course 5", path: "/第三課.mp4" },
        { id: 3, name: "Course 6", path: "/第三課.mp4" },
        { id: 3, name: "Course 7", path: "/第三課.mp4" },
        { id: 3, name: "Course 8", path: "/第三課.mp4" },
    ]);

    const [gridData, setGridData] = useState(Array(9).fill(null));

    const onDrop = (index, item) => {
        const newData = [...gridData];
        newData[index] = item;
        setGridData(newData);
    };

    // only for MultipleGridContainer and MultipleGridContainerV2
    const handleRemoveVideo = (index) => {
        const newGridData = [...gridData];
        newGridData[(index + colN * (rowN + 1)) % (colN * (rowN + 1))] = null;
        setGridData(newGridData);
    };

    useEffect(() => {
        console.log("Setting background to none");
        // 當進入該頁面時，移除背景圖片
        document.body.style.backgroundImage = "none";

        // 當離開該頁面時，清理函數將被調用，恢復背景圖片
        return () => {
            console.log("Restoring background image");
            document.body.style.backgroundImage = 'url("/bgImg.png")';
        };
    }, [location.pathname]); // 依賴於 location.pathname

    return (
        <Container fluid className="multiple_grid_page_container_flex p-4">
            <div className="multiple_grid_page_content">
                <Row style={{ paddingLeft: "3vw" }}>
                    <Col md="7">
                        <LogoButton />
                    </Col>
                </Row>
                <div className="content-area">
                    <>
                        {/* <Row>
                            <Col>
                                <button onClick={() => setShowPlayer(true)}>
                                    產生影片
                                </button>
                            </Col>
                        </Row> */}
                        <div
                            style={{
                                width: "60%",
                                margin: "auto",
                            }}
                        >
                            <Row
                                className="justify-content-center"
                                style={{
                                    paddingLeft: "1vw",
                                    paddingRight: "1vw",
                                }}
                            >
                                <Col md={2} />
                                <Col className="text-center">星期一</Col>
                                <Col className="text-center">星期二</Col>
                                <Col className="text-center">星期三</Col>
                                <Col className="text-center">星期四</Col>
                                <Col className="text-center">星期五</Col>
                            </Row>
                            <Row>
                                <Col
                                    className="d-flex justify-content-center"
                                    style={{
                                        paddingTop: "2vh",
                                        paddingBottom: "2vh",
                                    }}
                                    md={2}
                                >
                                    <div>
                                        <div
                                            style={{
                                                height: "25%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            上午一
                                        </div>
                                        <div
                                            style={{
                                                height: "25%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            上午二
                                        </div>
                                        <div
                                            style={{
                                                height: "25%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            下午一
                                        </div>
                                        <div
                                            style={{
                                                height: "25%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            下午二
                                        </div>
                                    </div>
                                </Col>
                                <Col>{renderSelectedContainer()}</Col>
                            </Row>
                        </div>
                        <Row className="p-3">
                            {videos.map((video) => (
                                <Col key={video.id}>
                                    <DraggableVideo video={video} />
                                </Col>
                            ))}
                        </Row>
                    </>
                </div>
            </div>
            <Row className="pt-4 multiple_grid_page_footer">
                <Col>
                    <Footer />
                </Col>
            </Row>
        </Container>
    );
}

export default DaySchedule;
