import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../css/AcademicTheoryBox.css";

function AcademicTheoryBox({ index, id, imgs }) {
    return (
        <Container fluid>
            <Row>
                <Col>
                    <img
                        key={index}
                        src={imgs[id].ref}
                        alt={`${id}`}
                        onClick={() => (window.location.href = imgs[id].url)}
                        className="academic_theory_box_img"
                    />
                </Col>
            </Row>
        </Container>
    );
}

export default AcademicTheoryBox;
