import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDrop } from 'react-dnd';

import "../css/MultipleGridContainer.css"

function GridCell({ index, gridData, onRemove }) {
    const handleRemove = () => {
        onRemove(index);
    };

    // 如果格子中有影片，顯示該影片和刪除按鈕
    if (gridData[index]) {
        return (
            <div className="grid-drop-area">
                <div className="grid-content">
                    {gridData[index].name}
                    <button onClick={handleRemove} className="remove-button">×</button>
                </div>
            </div>
        );
    }

    // 如果格子中沒有影片，僅返回空的 div
    return <div className="grid-drop-area"></div>;
}

function findNextSnakingIndex(gridData, rowCount, colCount) {
    const totalCells = rowCount * colCount;
  
    for (let i = 0; i < totalCells; i++) {
      if (!gridData[i]) {
        return i;
      }
    }
  
    return -1; // No empty cell found
  }
  
  function MultipleGridContainerV3({ gridData, onDrop, onRemove }) {
    const rowCount = 3;
    const colCount = 3;
  
    const [{ isOver }, dropRef] = useDrop({
      accept: 'video',
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
      drop: (item) => {
        const droppedData = item;
        if (droppedData && droppedData.video) {
          const droppedVideo = JSON.parse(droppedData.video);
  
          // Find the next snaking index in the grid
          const nextIndex = findNextSnakingIndex(gridData, rowCount, colCount);
  
          if (nextIndex !== -1) {
            onDrop(nextIndex, droppedVideo);
          }
        }
      },
    });
  
    return (
      <Container
        fluid
        className={`multiple_grid_container_background_primary ${isOver ? 'is-over' : ''}`}
        ref={dropRef}
      >
        {Array.from({ length: rowCount }).map((_, rowIndex) => (
          <Row key={rowIndex}>
            {Array.from({ length: colCount }).map((_, colIndex) => {
              const index = rowIndex % 2 === 0 
                ? rowIndex * colCount + colIndex 
                : rowIndex * colCount + (colCount - colIndex - 1);
              
              return (
                <Col key={colIndex}>
                  <GridCell
                    index={index}
                    gridData={gridData}
                    onRemove={onRemove}
                  />
                </Col>
              );
            })}
          </Row>
        ))}
      </Container>
    );
  }
  

export default MultipleGridContainerV3;
