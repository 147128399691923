import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import LanguageContext from "../../languageSwitch/LanguageContext";
import translations from "../../translations.json";
import "../css/CourseTestButton.css";

function CourseTestButton({ jsonFileName }) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/courseTest", { state: { filename: jsonFileName } });
    };

    const { language } = useContext(LanguageContext);

    return (
        <button className="course_test_button_primary" onClick={handleClick}>
            {translations[language].test}
        </button>
    );
}

export default CourseTestButton;
