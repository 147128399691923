import React, { useContext, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LanguageContext from "../languageSwitch/LanguageContext";
import LogoButton from "../button/components/LogoButton";
import AcademicTheoryContainer from "./components/AcademicTheoryContainer";
import CourseButton from "../button/components/CourseButton";
import Footer from "./components/Footer";

import translations from "../translations.json";
import "./css/AcademicTheoryPage.css";

function AcademicTheoryPage() {
    const [selectedLeftButton, setSelectedLeftButton] = useState("course");
    const [selectedRightButton, setSelectedRightButton] = useState("oneToTwo");

    const { language } = useContext(LanguageContext);

    const leftButtons = ["course", "theory", "film", "extend"];
    const rightButtons = ["oneToTwo", "TwoToThree", "ThreeToFour", "FourToSix"];

    const handleLeftButtonClick = (button) => {
        setSelectedLeftButton(button);
        const firstRightButton = rightButtons[0];
        setSelectedRightButton(firstRightButton);
    };

    const handleRightButtonClick = (button) => {
        setSelectedRightButton(button);
    };

    return (
        <Container fluid className="academic_theory_page_container_flex p-4">
            <div className="academic_theory_page_content">
                <Row style={{ paddingLeft: "3vw" }}>
                    <Col md="7">
                        <LogoButton />
                    </Col>
                </Row>
                <Row className="pt-0">
                    <Col md="2" className="buttonCol order-md-1 order-1">
                        <div className="academic_theory_page_button_left">
                            {leftButtons.map((button) => (
                                <CourseButton
                                    onClick={() =>
                                        handleLeftButtonClick(button)
                                    }
                                    selected={button === selectedLeftButton}
                                    label={translations[language][button]}
                                />
                            ))}
                        </div>
                    </Col>
                    <Col md="8" className="order-md-2 order-2">
                        <AcademicTheoryContainer />
                    </Col>
                    <Col md="2" className="buttonCol order-md-3 order-1">
                        <div className="academic_theory_page_button_right">
                            {rightButtons.map((button) => (
                                <CourseButton
                                    onClick={() =>
                                        handleRightButtonClick(button)
                                    }
                                    selected={button === selectedRightButton}
                                    label={translations[language][button]}
                                />
                            ))}
                        </div>
                    </Col>
                </Row>
            </div>
            <Row className="pt-4 academic_theory_page_footer">
                <Col>
                    <Footer />
                </Col>
            </Row>
        </Container>
    );
}

export default AcademicTheoryPage;
