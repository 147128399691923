import React from 'react';
import { Link, useLocation } from 'react-router-dom'; // 加入 useLocation

function VideoListPage(){
    const location = useLocation(); // 使用 useLocation hook
    const videoList = location.state.playlist;

    return (
        <div>
            <h2>Generated Video List</h2>
            <ul>
                {videoList.map(video => (
                    <li key={video.uniqueId}>
                        {video.ref}
                    </li>
                ))}
            </ul>
            <Link to="/">Back</Link>
        </div>
    );
};

export default VideoListPage;
