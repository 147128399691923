import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../css/MultipleVideoPlayer.css";

function MultipleVideoPlayer({ sources, onCanPlay }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [currentVideo, setCurrentVideo] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [progress, setProgress] = useState(0);
    const [totalDuration, setTotalDuration] = useState(0);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false);

    const videoRefs = useRef(sources.map(() => React.createRef()));
    const hideProgressBarTimeoutRef = useRef(null);
    const videoAndControlsRef = useRef(null);

    useEffect(() => {
        const videoElements = videoRefs.current; // Store the current ref value to a variable

        const updateProgress = () => {
            videoRefs.current.forEach((videoRef, index) => {
                if (videoRef.current) {
                    console.log(
                        "Video",
                        index,
                        "duration:",
                        videoRef.current.duration
                    );
                }
            });
            let totalPlayedTime = videoRefs.current
                .slice(0, currentVideo)
                .reduce(
                    (acc, videoRef) => acc + (videoRef.current?.duration || 0),
                    0
                );
            totalPlayedTime +=
                videoRefs.current[currentVideo]?.current?.currentTime || 0;

            const totalDuration = videoRefs.current.reduce(
                (acc, videoRef) => acc + (videoRef.current?.duration || 0),
                0
            );
            setCurrentTime(totalPlayedTime);
            setProgress((totalPlayedTime / totalDuration) * 100);
        };

        const handleMetadataLoaded = () => {
            const duration = videoRefs.current.reduce(
                (acc, videoRef) => acc + (videoRef.current?.duration || 0),
                0
            );
            setTotalDuration(duration);
            updateProgress();
        };

        for (let videoRef of videoElements) {
            if (videoRef.current) {
                videoRef.current.addEventListener("timeupdate", () => {
                    updateProgress();
                });
                videoRef.current.addEventListener(
                    "loadedmetadata",
                    handleMetadataLoaded
                );
            }
        }

        return () => {
            for (let videoRef of videoElements) {
                if (videoRef.current) {
                    videoRef.current.removeEventListener(
                        "timeupdate",
                        updateProgress
                    );
                    videoRef.current.removeEventListener(
                        "loadedmetadata",
                        handleMetadataLoaded
                    );
                }
            }
        };
    }, [currentVideo]);

    const checkFullScreen = () => {
        // 添加此函数，以检测是否处于全屏模式
        const fullscreenElement =
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement;
        setIsFullScreen(!!fullscreenElement);
    };

    useEffect(() => {
        document.addEventListener("fullscreenchange", checkFullScreen);
        document.addEventListener("webkitfullscreenchange", checkFullScreen);
        document.addEventListener("mozfullscreenchange", checkFullScreen);
        document.addEventListener("MSFullscreenChange", checkFullScreen);

        return () => {
            document.removeEventListener("fullscreenchange", checkFullScreen);
            document.removeEventListener(
                "webkitfullscreenchange",
                checkFullScreen
            );
            document.removeEventListener(
                "mozfullscreenchange",
                checkFullScreen
            );
            document.removeEventListener("MSFullscreenChange", checkFullScreen);
        };
    }, []);

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseLeave = () => {
        if (isDragging) setIsDragging(false);
    };

    const showAndHideProgressBar = () => {
        clearTimeout(hideProgressBarTimeoutRef.current);
        setShowProgressBar(true);

        hideProgressBarTimeoutRef.current = setTimeout(() => {
            setShowProgressBar(false);
        }, 1000);
    };

    const handlePlayPause = () => {
        if (videoRefs.current[currentVideo].current.paused) {
            videoRefs.current[currentVideo].current.play();
            setIsPlaying(true);
        } else {
            videoRefs.current[currentVideo].current.pause();
            setIsPlaying(false);
        }
    };

    const handleVideoEnd = () => {
        if (currentVideo < sources.length - 1) {
            setCurrentVideo(currentVideo + 1);
            videoRefs.current[currentVideo + 1].current.play();
        }
    };

    const handleVideoMouseEnter = () => {
        setShowProgressBar(true);
    };

    const handleVideoMouseLeave = () => {
        setShowProgressBar(false);
    };

    const playVideoAtIndex = async (index) => {
        if (videoRefs.current[index].current) {
            try {
                await videoRefs.current[index].current.play();
                setIsPlaying(true);
            } catch (error) {
                console.error("Error occurred during play:", error);
            }
        }
    };

    const pauseAllVideosExcept = async (exceptIndex) => {
        for (let i = 0; i < videoRefs.current.length; i++) {
            if (
                i !== exceptIndex &&
                videoRefs.current[i].current &&
                !videoRefs.current[i].current.paused
            ) {
                videoRefs.current[i].current.pause();
            }
        }
    };

    const handleMouseMove = async (e) => {
        showAndHideProgressBar();

        if (!isDragging) return;
        const rect = e.currentTarget.getBoundingClientRect();
        const clickPosition = e.clientX - rect.left;
        const newProgress = (clickPosition / rect.width) * 100;

        const newTime = (newProgress / 100) * totalDuration;

        let accumulatedTime = 0;
        for (let i = 0; i < videoRefs.current.length; i++) {
            const videoDuration = videoRefs.current[i].current.duration;
            if (accumulatedTime + videoDuration >= newTime) {
                if (currentVideo !== i) {
                    await pauseAllVideosExcept(i);
                    setCurrentVideo(i);
                }
                videoRefs.current[i].current.currentTime =
                    newTime - accumulatedTime;
                if (isPlaying) {
                    await playVideoAtIndex(i);
                }
                break;
            }
            accumulatedTime += videoDuration;
        }

        setProgress(newProgress);
    };

    const handleVideoClick = async (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const clickPosition = e.clientX - rect.left;
        const newProgress = (clickPosition / rect.width) * 100;

        const newTime = (newProgress / 100) * totalDuration;

        if (isFinite(newTime)) {
            setProgress(newProgress);

            let accumulatedTime = 0;
            for (let i = 0; i < videoRefs.current.length; i++) {
                const videoDuration = videoRefs.current[i].current.duration;
                if (accumulatedTime + videoDuration >= newTime) {
                    if (currentVideo !== i) {
                        await pauseAllVideosExcept(i);
                        setCurrentVideo(i);
                    }
                    videoRefs.current[i].current.currentTime =
                        newTime - accumulatedTime;
                    if (isPlaying) {
                        await playVideoAtIndex(i);
                    }
                    break;
                }
                accumulatedTime += videoDuration;
            }
        }
    };

    const handleDotMouseDown = (e) => {
        e.stopPropagation(); // 防止進度條的點擊事件被觸發
        e.preventDefault(); // 防止預設的拖動行為
        setIsDragging(true);
    };

    const toggleFullScreen = () => {
        if (isFullScreen) {
            // 如果当前处于全屏模式，则退出全屏
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        } else {
            // 如果当前不是全屏模式，则进入全屏
            const element = videoAndControlsRef.current;

            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            }
        }
    };

    const formatTime = (time) => {
        if (!isFinite(time) || time < 0) {
            return "00:00";
        }
        return new Date(time * 1000).toISOString().substr(14, 5);
    };

    return (
        <Container
            fluid
            className="multiple_video_player_background_primary align-items-center"
            onMouseEnter={handleVideoMouseEnter}
            onMouseLeave={handleVideoMouseLeave}
        >
            <div ref={videoAndControlsRef}>
                <Row>
                    <Col onMouseMove={handleMouseMove}>
                        {sources.map((source, index) => (
                            <video
                                key={index}
                                className={`multiple_video_player ${
                                    index !== currentVideo ? "hidden" : ""
                                }`}
                                ref={videoRefs.current[index]}
                                onClick={handlePlayPause}
                                onEnded={handleVideoEnd}
                                onCanPlay={onCanPlay}
                                preload={"auto"}
                            >
                                <source src={source} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        ))}
                        <div
                            className={`multiple_video_player_button ${
                                isPlaying ? "hidden" : ""
                            } ${showProgressBar ? "" : "hidden"}`}
                            onClick={handlePlayPause}
                        >
                            <img src="/videoBtn_play.png" alt="icon" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div
                            className={`controls ${
                                showProgressBar ? "" : "hidden"
                            }`}
                        >
                            <div
                                className="multiple_video_player_progress_bar"
                                onClick={handleVideoClick}
                                onMouseMove={handleMouseMove}
                                onMouseUp={handleMouseUp}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div
                                    className="multiple_video_player_progress_bar_fill"
                                    style={{ width: `${progress}%` }}
                                >
                                    <img
                                        src="/timeBall.png"
                                        alt="icon"
                                        draggable="false"
                                        className="multiple_video_player_progress_dot"
                                        onMouseDown={handleDotMouseDown}
                                    ></img>
                                </div>
                            </div>
                            <div className="multiple_video_player_time">
                                {formatTime(currentTime)}/
                                {formatTime(totalDuration)}
                            </div>
                            <button
                                className="multiple_video_player_full_screen_btn"
                                onClick={toggleFullScreen}
                            >
                                <img
                                    src={
                                        isFullScreen
                                            ? "/videoBtn_zoomOut.png"
                                            : "/videoBtn_fullScreen.png"
                                    }
                                    alt="icon"
                                />
                            </button>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    );
}

export default MultipleVideoPlayer;
