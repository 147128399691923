import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import "../css/VideoPlayer.css";

function VideoPlayer() {
    const location = useLocation(); // 使用 useLocation hook
    const videos = location.state.playlist;

    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [isFirstPlay, setIsFirstPlay] = useState(true);
    const [videoDurations, setVideoDurations] = useState([]);
    const [overallProgress, setOverallProgress] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [activeVideoRefIndex, setActiveVideoRefIndex] = useState(0);
    const videoRef1 = useRef(null);
    const videoRef2 = useRef(null);
    const videoRefs = useMemo(() => [videoRef1, videoRef2], []);
    const getInactiveVideoRef = useCallback(() => videoRefs[(activeVideoRefIndex + 1) % 2], [activeVideoRefIndex, videoRefs]);

    const [dragging, setDragging] = useState(false);
    const progressBarRef = useRef(null);

    const handlePlay = async () => {
        if (isFirstPlay) {
            videoRefs[activeVideoRefIndex].current.src = videos[currentVideoIndex].ref;
            videoRefs[activeVideoRefIndex].current.load();
            await playVideo(videoRefs[activeVideoRefIndex].current);
            setIsFirstPlay(false);
        }
    };

    const togglePlayPause = () => {
        if (videoRefs[activeVideoRefIndex].current.paused) {
            if (currentVideoIndex === videos.length - 1) {
                setCurrentVideoIndex(0); // Reset to the first video
                playVideo(videoRefs[activeVideoRefIndex].current);
            } else {
                playVideo(videoRefs[activeVideoRefIndex].current);
            }
            setIsPlaying(true);
        } else {
            videoRefs[activeVideoRefIndex].current.pause();
            setIsPlaying(false);
        }
    };
    

    const fetchVideoDuration = (videoSrc) => {
        return new Promise((resolve) => {
            const videoElement = document.createElement('video');
            videoElement.src = videoSrc.ref;

            videoElement.addEventListener('loadedmetadata', () => {
                resolve(videoElement.duration);
            });
        });
    };

    const playVideo = async (videoElement) => {
        try {
            await videoElement.play();
            setIsPlaying(true);
        } catch (error) {
            console.error("Error playing video:", error);
        }
    };

    useEffect(() => {
        const handleVideoEnd = () => {
            if (currentVideoIndex === videos.length - 1) {
                setIsPlaying(false); // Stop playing at the end of the last video
            } else {
                const newIndex = (currentVideoIndex + 1) % videos.length;
        
                setActiveVideoRefIndex((prevIndex) => (prevIndex + 1) % 2);
                setCurrentVideoIndex(newIndex);
                playVideo(videoRefs[activeVideoRefIndex].current);
            }
        };

        const handleTimeUpdate = () => {
            const currentVideoProgress = videoRefs[activeVideoRefIndex].current.currentTime;
            const progressBeforeCurrentVideo = videoDurations
                .slice(0, currentVideoIndex)
                .reduce((acc, curr) => acc + curr, 0);

            const totalDuration = videoDurations.reduce((acc, curr) => acc + curr, 0);
            setOverallProgress((currentVideoProgress + progressBeforeCurrentVideo) / totalDuration);
        };

        const videoElement = videoRefs[activeVideoRefIndex].current;
        videoElement.addEventListener('ended', handleVideoEnd);
        videoElement.addEventListener('timeupdate', handleTimeUpdate);

        return () => {
            videoElement.removeEventListener('ended', handleVideoEnd);
            videoElement.removeEventListener('timeupdate', handleTimeUpdate);
        };
    }, [currentVideoIndex, videoDurations, activeVideoRefIndex, videoRefs, videos.length]);

    useEffect(() => {
        if (videos.length > 1) {
            getInactiveVideoRef().current.src = videos[(currentVideoIndex + 1) % videos.length].ref;
            getInactiveVideoRef().current.load();
        }
    }, [currentVideoIndex, getInactiveVideoRef, videos]);

    useEffect(() => {
        const fetchAllDurations = async () => {
            const durations = await Promise.all(videos.map(fetchVideoDuration));
            setVideoDurations(durations);
        };

        fetchAllDurations();
    }, [videos]);

    useEffect(() => {
        console.log('Attempting to play the video with index:', currentVideoIndex);
        
        const handleCanPlayThrough = () => {
            if (!isFirstPlay) {
                playVideo(videoRefs[activeVideoRefIndex].current);
            }
        };
    
        videoRefs[activeVideoRefIndex].current.src = videos[currentVideoIndex].ref;
        videoRefs[activeVideoRefIndex].current.addEventListener('canplaythrough', handleCanPlayThrough);
        videoRefs[activeVideoRefIndex].current.load();
    
        // 清除事件監聽器
        return () => {
            if (videoRefs[activeVideoRefIndex].current) {
                videoRefs[activeVideoRefIndex].current.removeEventListener('canplaythrough', handleCanPlayThrough);
            }
        };
    }, [currentVideoIndex, activeVideoRefIndex, isFirstPlay, videoRefs, videos]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                setIsFirstPlay(true); // Requiring the user to play again
                setIsPlaying(false);  // Not playing automatically
                setCurrentVideoIndex(0); // Reset to the first video
                videoRefs[activeVideoRefIndex].current.currentTime = 0; // Reset video to the start
            }
        };
    
        document.addEventListener("visibilitychange", handleVisibilityChange);
    
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [activeVideoRefIndex, videoRefs]);
    

    const getEventPosition = (e) => {
        if (e.type.startsWith("touch")) {
            return e.touches[0].clientX;
        } else {
            return e.clientX;
        }
    };

    const startDrag = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const doDrag = (e) => {
        if (!dragging || !progressBarRef.current) return;

        const rect = progressBarRef.current.getBoundingClientRect();
        const newProgress = (getEventPosition(e) - rect.left) / rect.width;
        setOverallProgress(newProgress);

        const totalDuration = videoDurations.reduce((acc, curr) => acc + curr, 0);
        const newTime = newProgress * totalDuration;
        videoRefs[activeVideoRefIndex].current.currentTime = newTime;
    };

    const endDrag = (e) => {
        if (!dragging) return;
        e.preventDefault();
        setDragging(false);
        togglePlayPause();
    };

    return (
        <div>
            {isFirstPlay ? (
                <button onClick={handlePlay}>Play Video</button>
            ) : (
                <button onClick={togglePlayPause}>
                    {isPlaying ? 'Pause' : (currentVideoIndex === videos.length - 1 ? 'Replay' : 'Play')}
                </button>
            )}
            {videoRefs.map((ref, index) => (
                <video
                    ref={ref}
                    width="400"
                    style={{ display: activeVideoRefIndex === index ? 'block' : 'none' }}
                    key={index}
                />
            ))}
            <div style={{ width: '400px', height: '20px', backgroundColor: 'grey' }}>
                <div style={{ width: `${overallProgress * 100}%`, height: '100%', backgroundColor: 'blue' }}></div>
            </div>
            <div 
                ref={progressBarRef}
                style={{ width: '400px', height: '20px', backgroundColor: 'grey' }}
                onMouseDown={startDrag}
                onMouseMove={doDrag}
                onMouseUp={endDrag}
                onTouchStart={startDrag}
                onTouchMove={doDrag}
                onTouchEnd={endDrag}
            >
                <div style={{ width: `${overallProgress * 100}%`, height: '100%', backgroundColor: 'blue' }}></div>
            </div>
        </div>
    );
};

export default VideoPlayer;
