import React from 'react';
import "../css/CourseButton.css";

// 小顆的登入的按鈕
function CourseButton({ onClick, label, selected }){
    const buttonStyle = selected ? "course_button_selected_primary" : "course_button_primary";

    return (
        <button onClick={onClick} className={buttonStyle}>{label}</button>
    );
};

export default CourseButton;