import React from 'react';
import "../css/CircleButtonSmall.css";

// 小顆的登入的按鈕
function CircleButtonSmall({ onClick, label, selected }){
    const buttonStyle = selected ? "circle_button_small_selected_primary" : "circle_button_small_primary";

    return (
        <button onClick={onClick} className={buttonStyle}>{label}</button>
    );
};

export default CircleButtonSmall;