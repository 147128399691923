import React, { useContext } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import LoginButton from "../../button/components/LoginButtonSmall";
import LanguageContext from "../../languageSwitch/LanguageContext";

import translations from "../../translations.json"
import "../css/LogInContainer.css";

function LogInContainer() {
    const { language } = useContext(LanguageContext);

    return (
        <Container fluid className="Login_container_background_primary">
            <Row className="login_container_header_section">
                <Col className="text-center">{translations[language]['login']}</Col>
            </Row>
            <Row className="login_container_content_section text-center">
                <Col>
                    <div className="login_container_input_label">{translations[language]['enterID']}</div>
                    <Form.Control type="text" placeholder={translations[language]['logInID']} />

                    <div className="login_container_input_label">{translations[language]['enterPassword']}</div>
                    <Form.Control type="password" placeholder={translations[language]['password']} />

                    <div className="pt-3 pb-5">
                        <LoginButton/>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default LogInContainer;
