import React, { useContext, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LanguageContext from "../languageSwitch/LanguageContext";
import LogoButton from "../button/components/LogoButton";
import CourseButton from "../button/components/CourseButton";
import CourseDisplayGrid from "./components/CourseDisplayGrid";
import Footer from "./components/Footer";
import translations from "../translations.json";
import "./css/PlatformPage.css";

function PlatformPage() {
    const { language } = useContext(LanguageContext);
    const [selectedLeftButton, setSelectedLeftButton] = useState("primary");

    const leftButtons = ["primary", "junior", "senior", "preschool"];

    const handleLeftButtonClick = (button) => {
        setSelectedLeftButton(button);
    };

    function getTitleBySelectedButton(button) {
        const titleMap = {
            primary: translations[language].schoolNamePrimary,
            junior: translations[language].schoolNameJunior,
            senior: translations[language].schoolNameSenior,
            preschool: translations[language].schoolNamePreschool,
        };
        return titleMap[button] || ""; // Default title if the key does not exist
    }

    function mapButtonToKey(button) {
        const mapping = {
            primary: "preschoolPrimary",
            junior: "preschoolJunior",
            senior: "preschoolSenior",
            preschool: "preschool",
        };
        return mapping[button] || button;
    }

    const images = {
        primary: [
            {
                ref: "/platformImg/preschoolPrimary-1.png",
                id: 1,
                url: "/primary/unitOne",
            },
            {
                ref: "/platformImg/preschoolPrimary-2.png",
                id: 2,
                url: "/primary/unitTwo",
            },
            {
                ref: "/platformImg/preschoolPrimary-3.png",
                id: 3,
                url: "/primary/unitThree",
            },
            {
                ref: "/platformImg/preschoolPrimary-4.png",
                id: 4,
                url: "/primary/unitFour",
            },
        ],
        junior: [
            {
                ref: "/platformImg/preschoolJunior-1.png",
                id: 1,
                url: "/junior/unitOne",
            },
            {
                ref: "/platformImg/preschoolJunior-2.png",
                id: 2,
                url: "/junior/unitTwo",
            },
            {
                ref: "/platformImg/preschoolJunior-3.png",
                id: 3,
                url: "/junior/unitThree",
            },
            {
                ref: "/platformImg/preschoolJunior-4.png",
                id: 4,
                url: "/junior/unitFour",
            },
        ],
        senior: [
            {
                ref: "/platformImg/preschoolSenior-1.jpg",
                id: 1,
                url: "/senior/unitOne",
            },
            {
                ref: "/platformImg/preschoolSenior-2.png",
                id: 2,
                url: "/senior/unitTwo",
            },
            {
                ref: "/platformImg/preschoolSenior-3.png",
                id: 3,
                url: "/senior/unitThree",
            },
            {
                ref: "/platformImg/preschoolSenior-4.png",
                id: 4,
                url: "/senior/unitFour",
            },
        ],
        preschool: [
            {
                ref: "/platformImg/preschool-1.png",
                id: 1,
                url: "/preschool/unitOne",
            },
            {
                ref: "/platformImg/preschool-2.png",
                id: 2,
                url: "/preschool/unitTwo",
            },
            {
                ref: "/platformImg/preschool-3.png",
                id: 3,
                url: "/preschool/unitThree",
            },
            {
                ref: "/platformImg/preschool-4.png",
                id: 4,
                url: "/preschool/unitFour",
            },
            {
                ref: "/platformImg/preschool-5.png",
                id: 5,
                url: "/preschool/unitFive",
            },
            {
                ref: "/platformImg/preschool-6.png",
                id: 6,
                url: "/preschool/unitSix",
            },
        ],
    };

    return (
        <Container fluid className="platform_page_container_flex p-4">
            <div className="platform_page_content">
                <Row>
                    <Col md="2" className="platform_page_logo">
                        <LogoButton />
                    </Col>
                </Row>
                <Row className="pt-0">
                    <Col md="2" className="buttonCol order-md-1 order-1">
                        <div className="platform_page_button_left">
                            {leftButtons.map((button) => (
                                <CourseButton
                                    onClick={() =>
                                        handleLeftButtonClick(button)
                                    }
                                    selected={button === selectedLeftButton}
                                    label={
                                        translations[language][
                                            mapButtonToKey(button)
                                        ]
                                    }
                                />
                            ))}
                        </div>
                    </Col>
                    <Col md="8" className="order-md-2 order-2">
                        <CourseDisplayGrid
                            title={getTitleBySelectedButton(selectedLeftButton)}
                            imgs={images[selectedLeftButton]}
                        />
                    </Col>
                </Row>
            </div>
            <Row className="pt-4 platform_page_footer">
                <Col>
                    <Footer />
                </Col>
            </Row>
        </Container>
    );
}

export default PlatformPage;
