import React, { useContext, useState, useEffect } from "react";
import TabReplace from "./TabReplace";
import LanguageContext from "../../languageSwitch/LanguageContext";

import "../css/SensoryIntegrationLeftTopRegion.css";

function SensoryIntegrationLeftTopRegion({
    content,
    onCastleClick,
    jsonFileName,
}) {
    const { language } = useContext(LanguageContext);

    const [data, setData] = useState(null);

    useEffect(() => {
        import(`../../${jsonFileName}.json`)
            .then((response) => {
                setData(response.default);
            })
            .catch((error) => {
                console.error("Error loading json file:", error);
                setData(null);
            });
    }, [jsonFileName]);

    if (!data) {
        return <div>Loading...</div>; // 或者可以返回任何其他的加载指示器
    }

    if (content === "default") {
        return (
            <div>
                <strong
                    style={{
                        fontSize: "42px",
                        color: "red",
                    }}
                >
                    {TabReplace(data[language].unitHomePageTitle)}
                </strong>
                <div style={{ marginTop: "3vh" }}>
                    {data[language].unitHomePageContent.map((text, index) => (
                        <p key={index}>{TabReplace(text)}</p>
                    ))}
                </div>
            </div>
        );
    } else {
        return (
            <div
                onClick={onCastleClick}
                style={{
                    cursor: "pointer",
                    paddingLeft: "3vh",
                }}
            >
                <div className="pb-2">
                    <strong
                        style={{
                            fontSize: "4vw",
                            color: "grey",
                        }}
                    >
                        {data[language].unitTitle}
                    </strong>
                </div>
                <div>
                    <strong
                        style={{
                            fontSize: "2vw",
                            color: "grey",
                        }}
                    >
                        Build the castle
                    </strong>
                </div>
            </div>
        );
    }
}

export default SensoryIntegrationLeftTopRegion;
