import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDrop } from 'react-dnd';

import "../css/MultipleGridContainer.css"

function GridCell({ index, gridData, onRemove }) {

    const handleRemove = () => {
        onRemove(index);
    };

    return (
        <div className="grid-drop-area">
            {gridData[index] ? (
                <div className="grid-content">
                    {gridData[index].name}
                    <button onClick={handleRemove} className="remove-button">×</button>
                </div>
            ) : (
                <div className="empty-cell">
                    {`Cell ${index + 1}`}
                </div>
            )}
        </div>
    );
}

function MultipleGridContainerV2({ gridData, onDrop, onRemove }) {
    const [{ isOver }, dropRef] = useDrop({
        accept: 'video',
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
        drop: (item) => {
            const droppedData = item;
            if (droppedData && droppedData.video) {
                const droppedVideo = JSON.parse(droppedData.video);

                // Find the first empty slot in the grid
                const emptySlotIndex = gridData.findIndex(slot => !slot);
                if (emptySlotIndex !== -1) {
                    onDrop(emptySlotIndex, droppedVideo);
                }
            }
        },
    });

    return (
        <Container 
            fluid 
            className={`multiple_grid_container_background_primary ${isOver ? 'is-over' : ''}`} 
            ref={dropRef}
        >
            {Array.from({ length: 3 }).map((_, rowIndex) => (
                <Row key={rowIndex}>
                    {Array.from({ length: 3 }).map((_, colIndex) => (
                        <Col key={colIndex}>
                            <GridCell 
                                index={rowIndex * 3 + colIndex} 
                                gridData={gridData}
                                onRemove={onRemove} 
                            />
                        </Col>
                    ))}
                </Row>
            ))}
        </Container>
    );
}

export default MultipleGridContainerV2;
