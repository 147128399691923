import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import LogoButton from "../button/components/LogoButton";
import LogInContainer from "./components/LogInContainer";
import Footer from "./components/Footer";

import "./css/LogInPage.css";

function LogInPage() {
    return (
        <Container fluid className="login_page_flex p-4">
            <div className="login_page_content">
                <Row style={{ paddingLeft: "3vw" }}>
                    <Col md="7">
                        <LogoButton />
                    </Col>
                </Row>
                <Row className="pt-0">
                    <Col></Col>
                    <Col md="6">
                        <LogInContainer />
                    </Col>
                    <Col></Col>
                </Row>
            </div>
            <Row className="pt-4 login_page_footer">
                <Col>
                    <Footer />
                </Col>
            </Row>
        </Container>
    );
}

export default LogInPage;
