import React, { useContext } from 'react';
import LanguageContext from '../../languageSwitch/LanguageContext';
import translations from "../../translations.json";
import "../css/LoginButtonSmall.css";

// 小顆的登入的按鈕
function LoginButton(){
    const { language } = useContext(LanguageContext);

    return (
        <button className='login_btn_small_primary'>{translations[language].login}</button>
    );
};

export default LoginButton;