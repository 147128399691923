import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import WhiteButton from "../button/components/WhiteButton";
// import LoginButtonSmall from "../button/components/LoginButtonSmall";
// import CourseButton from "../button/components/CourseButton";
// import CircleButtonSmall from "../button/components/CircleButtonSmall";
// import BookButton from "../button/components/BookButton";
// import CourseTestButton from "../button/components/CourseTestButton";
// import UserButton from "../button/components/UserButton";
// import SchoolButton from "../button/components/SchoolButton";
// import PersonInfoButton from "../button/components/PersonInfoButton";
// import LogoButton from "../button/components/LogoButton";
// import LoginButton from "../button/components/LoginButtonSmall";
// import HomeButton from "../button/components/HomeButton";

import LanguageSwitchButton from "../button/components/LanguageSwitchButton";
import LanguageContext from "../languageSwitch/LanguageContext";
import translations from "../translations.json";

function TestPage() {
    //用來處理轉換語言的功能
    const { language, switchLanguage } = useContext(LanguageContext);

    return (
        <Container fluid className="p-4">
            <Row>
                <Col>
                    <LanguageSwitchButton
                        language={language}
                        setLanguage={switchLanguage}
                        translations={translations}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <WhiteButton label={"測能頁面"} nav={"/courseTest"} />
                </Col>
                <Col>
                    <WhiteButton label={"感統頁面"} nav={"/sensory/main"} />
                </Col>
                <Col>
                    <WhiteButton label={"日程安排"} nav={"/daySchedule"} />
                </Col>
                <Col>
                    <WhiteButton label={"登入頁面"} nav={"/login"} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <WhiteButton label={"學理頁面"} nav={"/academicTheory"} />
                </Col>
                <Col>
                    <WhiteButton label={"播放器頁面"} nav={"/singleVideo"} />
                </Col>
                <Col>
                    <WhiteButton label={"主頁面"} nav={"/"} />
                </Col>
                <Col>
                    <WhiteButton label={"平台頁面"} nav={"/platform"} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <WhiteButton label={"九宮格頁面"} nav={"/multipleGrid"} />
                </Col>
                <Col>
                    <WhiteButton label={"語音小插件"} nav={"/textToSpeech"} />
                </Col>
            </Row>
        </Container>
    );
}

export default TestPage;
