import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CourseDisplay from "./CourseDisplay";
import "../css/CourseDisplayGrid.css";

function CourseDisplayGrid({ title, imgs }) {
    const halfLength = Math.ceil(imgs.length / 2);
    const firstRowImages = imgs.slice(0, halfLength);
    const secondRowImages = imgs.slice(halfLength);

    return (
        <Container fluid className="course_display_grid_background_primary">
            <Row className="course_display_grid_arrangement">
                {firstRowImages.map((source, index) => (
                    <Col key={index}>
                        <CourseDisplay
                            id={source.id}
                            source={source.ref}
                            alt={source.ref}
                            url={source.url}
                        />
                    </Col>
                ))}
            </Row>
            <Row className="course_display_grid_arrangement">
                {secondRowImages.map((source, index) => (
                    <Col key={index}>
                        <CourseDisplay
                            id={source.id}
                            source={source.ref}
                            alt={source.ref}
                            url={source.url}
                        />
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default CourseDisplayGrid;
