import React from "react";
import { useNavigate } from "react-router-dom";
import "../css/LogoButton.css";

// 回主頁面的按鈕
function LogoButton() {
    const navigate = useNavigate();

    const handleGenerate = () => {
        navigate("/");
    };

    return (
        <button onClick={handleGenerate} className="logo_button_primary">
            <img
                src="/page_logo.png"
                className="log_button_img_primary"
                alt="logo"
            />
        </button>
    );
}

export default LogoButton;
