import React from 'react';
import "../css/LanguageSwitchButton.css"

function LanguageSwitchButton({ setLanguage }) {
    return (
        <div className='language_switch_btn_primary'>
            <button className='language_switch_btn_left' onClick={() => setLanguage('zhCN')}>
                {'简体中文'}
            </button>
            <button className='language_switch_btn_right' onClick={() => setLanguage('zhTW')}>
                {'繁體中文'}
            </button>
        </div>
    );
}

export default LanguageSwitchButton;
