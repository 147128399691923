import React from "react";
import "../css/SensoryButton.css";

// 小顆的登入的按鈕
function SensoryButton({ onClick, label, selected }) {
    const buttonStyle = selected
        ? "sensory_button_selected_primary"
        : "sensory_button_primary";

    return (
        <button onClick={onClick} className={buttonStyle}>
            {label}
        </button>
    );
}

export default SensoryButton;
