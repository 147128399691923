import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LanguageContext from "../../languageSwitch/LanguageContext";
import translations from "../../translations.json";
import "../css/CourseDisplay.css";

function CourseDisplay({ id, index, source, alt, url }) {
    const { language } = useContext(LanguageContext);

    function getTitleById(num) {
        const titleMap = {
            1: translations[language].oneUnit,
            2: translations[language].twoUnit,
            3: translations[language].threeUnit,
            4: translations[language].fourUnit,
            5: translations[language].fiveUnit,
            6: translations[language].sixUnit,
        };
        return titleMap[num] || "";
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                    <button
                        className="course_display_image_button"
                        onClick={() => (window.location.href = url)}
                    >
                        <img
                            key={index}
                            src={source}
                            alt={alt}
                            className="course_display_image"
                        />
                        <div className="course_display_title">
                            {getTitleById(id)}
                        </div>
                    </button>
                </Col>
            </Row>
        </Container>
    );
}

export default CourseDisplay;
