import React, { useState, useEffect } from 'react';
import LanguageContext from './LanguageContext';

function LanguageProvider({ children }) {
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'zhCN');

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
            setLanguage(storedLanguage);
        }
    }, []);

    const switchLanguage = (lng) => {
        setLanguage(lng);
        localStorage.setItem('language', lng);
    };

    return (
        <LanguageContext.Provider value={{ language, switchLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
}

export default LanguageProvider;
