import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import AcademicTheoryBox from "./AcademicTheoryBox";
import "../css/AcademicTheoryContainer.css";

function chunkArray(array, size) {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }
    return result;
}

function AcademicTheoryContainer() {
    const images = {
        1: {
            ref: "/platformImg/preschoolPrimary-1.png",
            id: 1,
            url: "/singleVideo",
        },
        2: {
            ref: "/platformImg/preschoolPrimary-2.png",
            id: 2,
            url: "/singleVideo",
        },
        3: {
            ref: "/platformImg/preschoolPrimary-3.png",
            id: 3,
            url: "/singleVideo",
        },
        4: {
            ref: "/platformImg/preschoolPrimary-4.png",
            id: 4,
            url: "/singleVideo",
        },
        5: {
            ref: "/platformImg/preschoolJunior-1.png",
            id: 5,
            url: "/singleVideo",
        },
        6: {
            ref: "/platformImg/preschoolJunior-2.png",
            id: 6,
            url: "/singleVideo",
        },
        7: {
            ref: "/platformImg/preschoolJunior-3.png",
            id: 7,
            url: "/singleVideo",
        },
        8: {
            ref: "/platformImg/preschoolJunior-4.png",
            id: 8,
            url: "/singleVideo",
        },
        9: {
            ref: "/platformImg/preschoolSenior-1.jpg",
            id: 9,
            url: "/singleVideo",
        },
        10: {
            ref: "/platformImg/preschoolSenior-2.png",
            id: 10,
            url: "/singleVideo",
        },
        11: {
            ref: "/platformImg/preschoolSenior-3.png",
            id: 11,
            url: "/singleVideo",
        },
        12: {
            ref: "/platformImg/preschoolSenior-4.png",
            id: 12,
            url: "/singleVideo",
        },
        13: {
            ref: "/platformImg/preschool-1.png",
            id: 13,
            url: "/singleVideo",
        },
        14: {
            ref: "/platformImg/preschool-2.png",
            id: 14,
            url: "/singleVideo",
        },
        15: {
            ref: "/platformImg/preschool-3.png",
            id: 15,
            url: "/singleVideo",
        },
        16: {
            ref: "/platformImg/preschool-4.png",
            id: 16,
            url: "/singleVideo",
        },
        17: {
            ref: "/platformImg/preschool-5.png",
            id: 17,
            url: "/singleVideo",
        },
        18: {
            ref: "/platformImg/preschool-6.png",
            id: 18,
            url: "/singleVideo",
        },
    };

    const components = Array.from({ length: 18 }, (_, i) => (
        <AcademicTheoryBox key={i} id={i + 1} imgs={images} />
    ));
    const chunks = chunkArray(components, 6);

    return (
        <Container
            fluid
            className="academic_theory_container_background_primary"
        >
            {chunks.map((chunk, rowIndex) => (
                <Row key={rowIndex}>
                    {chunk.map((comp) => (
                        <Col key={comp.key}>{comp}</Col>
                    ))}
                </Row>
            ))}
        </Container>
    );
}

export default AcademicTheoryContainer;
