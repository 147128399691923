import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import TabReplace from "./TabReplace";
import LanguageContext from "../../languageSwitch/LanguageContext";
import translations from "../../translations.json";
import "../css/Footer.css";

function Footer(){
    const { language } = useContext(LanguageContext);

    return (
        <Container fluid>
            <Row>
                <Col style={{ textAlign: "center" }}><div>{translations[language].contactHint}</div></Col>
            </Row>
            <Row>
                <Col style={{ textAlign: "center" }}><div>{TabReplace(translations[language].copyright)}</div></Col>
            </Row>
        </Container>
    );
};

export default Footer;