import React from 'react';
import { useNavigate } from 'react-router-dom';
import "../css/WhiteButton.css";

// 白底橘字的按鈕
function WhiteButton({ label, nav }){
    const navigate = useNavigate();
    
    const handleGenerate = () => {
        navigate(nav);
    }  

    return (<button className='white_btn_primary' onClick={handleGenerate}>{label}</button>);
};

export default WhiteButton;