import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "./components/Footer";
import LogoButton from "../button/components/LogoButton";
import LanguageContext from "../languageSwitch/LanguageContext";
import CourseButton from "../button/components/CourseButton";
import MultipleVideoPlayer from "./components/MultipleVideoPlayer";
import LoadingRing from "./components/LoadingRing";
import testVideoPath from "../testVideoPath.json";
import translations from "../translations.json";
import "./css/CourseTestPage.css";

function CourseTestPage() {
    const { language } = useContext(LanguageContext);
    const location = useLocation();
    const [selectedLeftButton, setSelectedLeftButton] = useState("createTest");
    const leftButtons = ["createTest", "storyTest", "exerciseTest"];
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const filename = location.state?.filename;

    const handleLeftButtonClick = (button) => {
        setSelectedLeftButton(button);
    };

    const handleVideoCanPlay = () => {
        setIsVideoLoaded(true);
    };

    useEffect(() => {
        setIsVideoLoaded(false); // 每次这些值改变时，重置视频加载状态
    }, [selectedLeftButton]);

    return (
        <Container fluid className="course_test_page_container_flex p-4">
            <div className="course_test_page_content">
                <Row>
                    <Col md="2" className="course_test_page_logo">
                        <LogoButton />
                    </Col>
                </Row>
                <Row className="pt-0">
                    <Col md="2" className="buttonCol order-md-1 order-1">
                        <div className="course_test_page_button_left">
                            {leftButtons.map((button) => (
                                <CourseButton
                                    key={button}
                                    onClick={() =>
                                        handleLeftButtonClick(button)
                                    }
                                    selected={button === selectedLeftButton}
                                    label={translations[language][button]}
                                />
                            ))}
                        </div>
                    </Col>
                    <Col md="8" className="order-md-2 order-2">
                        <div className="course_test_page_player_container">
                            {!isVideoLoaded && (
                                <div className="course_test_page_loading_ring">
                                    <LoadingRing />
                                </div>
                            )}
                            <div
                                style={{
                                    display: isVideoLoaded ? "block" : "none",
                                }}
                            >
                                <MultipleVideoPlayer
                                    key={
                                        testVideoPath[filename][
                                            selectedLeftButton
                                        ]
                                    }
                                    sources={[
                                        testVideoPath[filename][
                                            selectedLeftButton
                                        ],
                                    ]}
                                    onCanPlay={handleVideoCanPlay}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md="2" className="buttonCol order-md-3 order-1" />
                </Row>
            </div>
            <Row className="pt-4 course_test_page_footer">
                <Col>
                    <Footer />
                </Col>
            </Row>
        </Container>
    );
}

export default CourseTestPage;
