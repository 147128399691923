import React, { useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import WhiteButton from "../button/components/WhiteButton";
import LanguageContext from "../languageSwitch/LanguageContext";
import UserButton from "../button/components/UserButton";
import BookButton from "../button/components/BookButton";
import LanguageSwitchButton from "../button/components/LanguageSwitchButton";
import LogoButton from "../button/components/LogoButton";
import Footer from "./components/Footer";

import translations from "../translations.json";
import "./css/MainPage.css";

function MainPage() {
    const location = useLocation();
    const { language, switchLanguage } = useContext(LanguageContext);

    useEffect(() => {
        // 當進入該頁面時，移除背景圖片
        document.body.style.backgroundImage = "none";

        // 當離開該頁面時，清理函數將被調用，恢復背景圖片
        return () => {
            document.body.style.backgroundImage = 'url("/bgImg.png")';
        };
    }, [location.pathname]); // 依賴於 location.pathname

    return (
        <Container fluid className="main_page_container_flex p-4">
            <div className="main_page_content">
                <Row>
                    <Col md="2" className="main_page_logo">
                        <LogoButton />
                    </Col>
                    <Col md="5">
                        <WhiteButton
                            label={translations[language].openCardCharge}
                        />
                        <WhiteButton
                            label={translations[language].newcomerGuide}
                        />
                    </Col>
                    <Col>
                        <UserButton />
                        <BookButton />
                        <LanguageSwitchButton
                            language={language}
                            setLanguage={switchLanguage}
                            translations={translations}
                        />
                    </Col>
                </Row>
            </div>
            <Row className="main_page_footer">
                <Col>
                    <Footer />
                </Col>
            </Row>
        </Container>
    );
}

export default MainPage;
