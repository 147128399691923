import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LogoButton from "../button/components/LogoButton";
import MultipleGridContainerV2 from "./components/MultipleGridContainerV2";
import MultipleGridContainer from "./components/MultipleGridContainer";
import MultipleGridContainerV3 from "./components/MultipleGridContainerV3";
import MultipleVideoPlayer from "./components/MultipleVideoPlayer";
import ClockContainer from "./components/ClockContainer";
import Footer from "./components/Footer";
import { useDrag } from "react-dnd";

import "./css/MultipleGridPage.css";

function DraggableVideo({ video }) {
    const [{ isDragging }, dragRef] = useDrag({
        type: "video",
        item: { video: JSON.stringify(video) },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    return (
        <button ref={dragRef} style={{ opacity: isDragging ? 0.5 : 1 }}>
            {video.name}
        </button>
    );
}

function MultipleGridPage() {
    const [selectedContainer, setSelectedContainer] =
        useState("ClockContainer"); // 新增的state

    const handleContainerChange = (containerType) => {
        setSelectedContainer(containerType);
        setGridData(Array(9).fill(null)); // 清空gridData的內容
    };

    const renderSelectedContainer = () => {
        switch (selectedContainer) {
            case "MultipleGridContainer":
                return (
                    <MultipleGridContainer
                        gridData={gridData}
                        onDrop={onDrop}
                        onRemove={handleRemoveVideo}
                    />
                );
            case "MultipleGridContainerV2":
                return (
                    <MultipleGridContainerV2
                        gridData={gridData}
                        onDrop={onDrop}
                        onRemove={handleRemoveVideo}
                    />
                );
            case "MultipleGridContainerV3":
                return (
                    <MultipleGridContainerV3
                        gridData={gridData}
                        onDrop={onDrop}
                        onRemove={handleRemoveVideo1}
                    />
                );
            case "ClockContainer":
                return (
                    <ClockContainer
                        gridData={gridData}
                        onDrop={clockOnDrop}
                        onRemove={handleRemoveVideo}
                    />
                );
            default:
                return null;
        }
    };

    const [videos /*, setVideos*/] = useState([
        { id: 1, name: "Video 1", path: "/第一課.mp4" },
        { id: 2, name: "Video 2", path: "/第二課.mp4" },
        { id: 3, name: "Video 3", path: "/第三課.mp4" },
    ]);

    const [gridData, setGridData] = useState(Array(9).fill(null));
    const [showPlayer, setShowPlayer] = useState(false);

    const onDrop = (index, item) => {
        const newData = [...gridData];
        newData[index] = item;
        setGridData(newData);
    };

    const clockOnDrop = (index, item) => {
        const newData = [...gridData];
        const idx = (index + 14) % 12;
        newData[idx] = item;
        setGridData(newData);
    };

    // only for MultipleGridContainer and MultipleGridContainerV2
    const handleRemoveVideo = (index) => {
        const newGridData = [...gridData];
        newGridData[(index + 14) % 12] = null;
        setGridData(newGridData);
    };

    // only for MultipleGridContainerV3
    const handleRemoveVideo1 = (index) => {
        const newGridData = [...gridData];
        for (let i = index; i < newGridData.length - 1; i++) {
            newGridData[i] = newGridData[i + 1];
        }
        newGridData[newGridData.length - 1] = null;
        setGridData(newGridData);
    };

    const handleBack = () => {
        setShowPlayer(false);
    };

    return (
        <Container fluid className="multiple_grid_page_container_flex p-4">
            <div className="multiple_grid_page_content">
                <Row style={{ paddingLeft: "3vw" }}>
                    <Col md="7">
                        <LogoButton />
                    </Col>
                </Row>
                <div className="content-area">
                    {!showPlayer ? (
                        <>
                            <Row>
                                更新的按鈕，使用handleContainerChange來切換容器和重置內容
                                <Col>
                                    <button
                                        onClick={() =>
                                            handleContainerChange(
                                                "MultipleGridContainer"
                                            )
                                        }
                                    >
                                        MultipleGridContainer
                                    </button>
                                </Col>
                                <Col>
                                    <button
                                        onClick={() =>
                                            handleContainerChange(
                                                "MultipleGridContainerV2"
                                            )
                                        }
                                    >
                                        MultipleGridContainerV2
                                    </button>
                                </Col>
                                <Col>
                                    <button
                                        onClick={() =>
                                            handleContainerChange(
                                                "MultipleGridContainerV3"
                                            )
                                        }
                                    >
                                        MultipleGridContainerV3
                                    </button>
                                </Col>
                                <Col>
                                    <button
                                        onClick={() =>
                                            handleContainerChange(
                                                "ClockContainer"
                                            )
                                        }
                                    >
                                        ClockContainer
                                    </button>
                                </Col>
                            </Row>
                            <Row>
                                {videos.map((video) => (
                                    <Col key={video.id}>
                                        <DraggableVideo video={video} />
                                    </Col>
                                ))}
                            </Row>
                            <Row>
                                <Col>
                                    <button onClick={() => setShowPlayer(true)}>
                                        產生影片
                                    </button>
                                </Col>
                            </Row>
                            <Row>
                                <Col></Col>
                                <Col md="8">{renderSelectedContainer()}</Col>
                                <Col></Col>
                            </Row>
                        </>
                    ) : (
                        <>
                            <Row>
                                <Col></Col>
                                <Col md="8">
                                    <MultipleVideoPlayer
                                        sources={gridData
                                            .filter((v) => v)
                                            .map((v) => v.path)}
                                    />
                                </Col>
                                <Col>
                                    <button onClick={handleBack}>返回</button>
                                </Col>
                            </Row>
                        </>
                    )}
                </div>
            </div>
            <Row className="pt-4 multiple_grid_page_footer">
                <Col>
                    <Footer />
                </Col>
            </Row>
        </Container>
    );
}

export default MultipleGridPage;
