import React, { useState, useEffect } from "react";

function TextToSpeech() {
    const [text, setText] = useState("");
    const [voices, setVoices] = useState([]);
    const [selectedVoice, setSelectedVoice] = useState(null);
    const [isSpeaking, setIsSpeaking] = useState(false);

    useEffect(() => {
        setVoices(speechSynthesis.getVoices());
        speechSynthesis.onvoiceschanged = () => {
            setVoices(speechSynthesis.getVoices());
        };
    }, []);

    const handleTextChange = (event) => {
        setText(event.target.value);
        if (isSpeaking) {
            speechSynthesis.cancel();
            setIsSpeaking(false);
        }
    };

    const handleVoiceChange = (event) => {
        setSelectedVoice(
            voices.find((voice) => voice.name === event.target.value)
        );
    };

    const toggleSpeech = () => {
        if (isSpeaking) {
            speechSynthesis.cancel();
            setIsSpeaking(false);
        } else {
            const utterance = new SpeechSynthesisUtterance(text);
            utterance.voice = selectedVoice;
            speechSynthesis.speak(utterance);
            setIsSpeaking(true);

            utterance.onend = () => {
                setIsSpeaking(false);
            };
            utterance.onerror = () => {
                setIsSpeaking(false);
            };
        }
    };

    return (
        <div>
            <textarea
                value={text}
                onChange={handleTextChange}
                placeholder="在此输入文字..."
                rows="4"
                cols="50"
            />
            <br />
            <select
                onChange={handleVoiceChange}
                value={selectedVoice?.name || ""}
            >
                {voices.map((voice, index) => (
                    <option key={index} value={voice.name}>
                        {voice.name} ({voice.lang})
                    </option>
                ))}
            </select>
            <br />
            <button onClick={toggleSpeech}>
                {isSpeaking ? "暂停" : "播放"}
            </button>
        </div>
    );
}

export default TextToSpeech;
