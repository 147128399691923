// LoadingRing.js
import React from 'react';
import '../css/LoadingRing.css';

function LoadingRing() {
    return (
        <div className="loading_ring_container">
            <div className="loading_ring"></div>
            <div className="loading_ring"></div>
            <div className="loading_ring"></div>
            <div className="loading_ring"></div>
            <h1>Loading...</h1>
        </div>
    );
}

export default LoadingRing;
