import React from "react";
import { useDrop } from "react-dnd";

import "../css/ClockContainer.css";

// function ClockCell({ index, gridData, onDrop, onRemove }) {
//     const [{ isOver }, dropRef] = useDrop({
//         accept: "video",
//         collect: (monitor) => ({
//             isOver: monitor.isOver(),
//         }),
//         drop: (item, monitor) => {
//             const droppedData = monitor.getItem();
//             if (droppedData && droppedData.video) {
//                 const droppedVideo = JSON.parse(droppedData.video);
//                 onDrop(index, droppedVideo);
//             }
//         },
//     });

//     const handleRemove = () => {
//         onRemove(index);
//     };

//     // 在 ClockCell 函式內部
//     const angle = (360 / 12) * index;

//     const style = {
//         transform: `rotate(${angle}deg) translateX(200px) rotate(-${angle}deg)`, // 使用 translateX 和調整值以確保方格對齊
//     };

//     return (
//         <div ref={dropRef} className="clock-drop-area" style={style}>
//             {gridData[(index + 14) % 12] ? (
//                 <div className={`clock-content ${isOver ? "is-over" : ""}`}>
//                     {gridData[(index + 14) % 12].name}
//                     <button onClick={handleRemove} className="remove-button">
//                         ×
//                     </button>
//                 </div>
//             ) : (
//                 <div className={`empty-cell ${isOver ? "is-over" : ""}`}>
//                     {`${((index + 14) % 12) + 1}點`}
//                 </div>
//             )}
//         </div>
//     );
// }

function ClockCell({ index, gridData, onDrop, onRemove }) {
    const [{ isOver }, dropRef] = useDrop({
        accept: "video",
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
        drop: (item, monitor) => {
            const droppedData = monitor.getItem();
            if (droppedData && droppedData.video) {
                const droppedVideo = JSON.parse(droppedData.video);
                onDrop(index, droppedVideo);
            }
        },
    });

    const handleRemove = () => {
        onRemove(index);
    };

    const style = {
        12: { left: "43%", top: "22%" },
        1: { left: "55.5%", top: "26.6%" },
        2: { left: "64.3%", top: "35.4%" },
        3: { left: "68.4%", top: "46.5%" },
        4: { left: "64.3%", top: "59.1%" },
        5: { left: "56.6%", top: "69.5%" },
        6: { left: "45.1%", top: "74.2%" },
        7: { left: "32.6%", top: "70.7%" },
        8: { left: "23.6%", top: "61.2%" },
        9: { left: "19.7%", top: "49.4%" },
        10: { left: "21.1%", top: "36.6%" },
        11: { left: "30.5%", top: "26.6%" },
    };

    return (
        <div
            ref={dropRef}
            className="clock-drop-area"
            style={style[((index + 14) % 12) + 1]}
        >
            {gridData[(index + 14) % 12] ? (
                <div className={`clock-content ${isOver ? "is-over" : ""}`}>
                    {gridData[(index + 14) % 12].name}
                    <button onClick={handleRemove} className="remove-button">
                        ×
                    </button>
                </div>
            ) : (
                <div className={`empty-cell ${isOver ? "is-over" : ""}`}>
                    {""}
                </div>
            )}
        </div>
    );
}

function ClockContainer({ gridData, onDrop, onRemove }) {
    const containerStyle = {
        backgroundImage: "url('/clock_bg.png')",
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
    };

    return (
        <div className="clock-container" style={containerStyle}>
            {Array.from({ length: 12 }).map((_, index) => (
                <ClockCell
                    key={index}
                    index={index}
                    gridData={gridData}
                    onDrop={onDrop}
                    onRemove={onRemove}
                />
            ))}
        </div>
    );
}

export default ClockContainer;
