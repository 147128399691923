import React, { useContext, useEffect, useState } from "react";
import TabReplace from "./TabReplace";
import LanguageContext from "../../languageSwitch/LanguageContext";
import SensoryIntegrationSteps from "./SensoryIntegrationSteps";
import ClickableGrid from "./ClickableGrid";

import "../css/SensoryIntegrationRightRegion.css";

function SensoryIntegrationRightRegion({ content, subContent, jsonFileName }) {
    const { language } = useContext(LanguageContext);

    const [data, setData] = useState(null);

    useEffect(() => {
        import(`../../${jsonFileName}.json`)
            .then((response) => {
                setData(response.default);
            })
            .catch((error) => {
                console.error("Error loading json file:", error);
                setData(null);
            });
    }, [jsonFileName]);

    if (!data) {
        return <div>Loading...</div>; // 或者可以返回任何其他的加载指示器
    }

    const translationEnTitleWithoutNumberMap = {
        evaluationLeftTopBtnName: data["en"].evaluationLeftTopBtnName,
        evaluationLeftBottomBtnName: data["en"].evaluationLeftBottomBtnName,
        evaluationRightTopBtnName: data["en"].evaluationRightTopBtnName,
        evaluationRightBottomBtnName: data["en"].evaluationRightBottomBtnName,
    };

    const translationEnSubTitleMap = {
        evaluationLeftTopBtnName: data["en"].evaluationLeftTopBtnContent,
        evaluationLeftBottomBtnName: data["en"].evaluationLeftBottomBtnContent,
        evaluationRightTopBtnName: data["en"].evaluationRightTopBtnContent,
        evaluationRightBottomBtnName:
            data["en"].evaluationRightBottomBtnContent,
    };

    const translationChTitleMap = {
        evaluationLeftTopBtnName: data[language].evaluationLeftTopBtnTitle,
        evaluationLeftBottomBtnName:
            data[language].evaluationLeftBottomBtnTitle,
        evaluationRightTopBtnName: data[language].evaluationRightTopBtnTitle,
        evaluationRightBottomBtnName:
            data[language].evaluationRightBottomBtnTitle,
    };

    const translationChSubTitleMap = {
        evaluationLeftTopBtnName: data[language].evaluationLeftTopBtnContent,
        evaluationLeftBottomBtnName:
            data[language].evaluationLeftBottomBtnContent,
        evaluationRightTopBtnName: data[language].evaluationRightTopBtnContent,
        evaluationRightBottomBtnName:
            data[language].evaluationRightBottomBtnContent,
    };

    if (content === "default") {
        return (
            <div className="pt-5">
                <img src={"/sensoryIntegrationImg/homeImg.jpg"} alt="" />
            </div>
        );
    } else if (content === "unit") {
        return (
            <div className="pt-5">
                <img src={"/sensoryIntegrationImg/homeImg.jpg"} alt="" />
            </div>
        );
    } else if (content === "target") {
        return (
            <div className="pt-5">
                {language === "en" ? (
                    <>
                        <div
                            style={{ display: "flex", alignItems: "baseline" }}
                        >
                            <p style={{ fontSize: "2.5vw", color: "red" }}>
                                {data[language].targetTitle}
                            </p>
                        </div>
                        <div
                            style={{
                                lineHeight: "2",
                                color: "grey",
                                paddingTop: "4vh",
                            }}
                        >
                            {data[language].targetContent}
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            style={{ display: "flex", alignItems: "baseline" }}
                        >
                            <p style={{ fontSize: "2.5vw", color: "red" }}>
                                {data[language].targetTitle}
                            </p>
                            <p style={{ paddingLeft: "3vw", color: "grey" }}>
                                {data["en"].targetTitle}
                            </p>
                        </div>
                        <div
                            style={{
                                lineHeight: "2",
                                color: "grey",
                                paddingTop: "4vh",
                            }}
                        >
                            {data[language].targetContent}
                        </div>
                        <div
                            style={{
                                lineHeight: "2",
                                color: "grey",
                                paddingTop: "4vh",
                            }}
                        >
                            {data["en"].targetContent}
                        </div>
                    </>
                )}
            </div>
        );
    } else if (content === "expert") {
        return (
            <div className="pt-5">
                {language === "en" ? (
                    <>
                        <div
                            style={{ display: "flex", alignItems: "baseline" }}
                        >
                            <p style={{ fontSize: "2.5vw", color: "red" }}>
                                {data[language].expertTitle}
                            </p>
                        </div>
                        <div
                            style={{
                                lineHeight: "2",
                                color: "grey",
                                paddingTop: "4vh",
                            }}
                        >
                            {data[language].expertContent}
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            style={{ display: "flex", alignItems: "baseline" }}
                        >
                            <p style={{ fontSize: "2.5vw", color: "red" }}>
                                {data[language].expertTitle}
                            </p>
                            <p style={{ paddingLeft: "3vw", color: "grey" }}>
                                {data["en"].expertTitle}
                            </p>
                        </div>
                        <div
                            style={{
                                lineHeight: "2",
                                color: "grey",
                                paddingTop: "4vh",
                            }}
                        >
                            {data[language].expertContent}
                        </div>
                        <div
                            style={{
                                lineHeight: "2",
                                color: "grey",
                                paddingTop: "4vh",
                            }}
                        >
                            {data["en"].expertContent}
                        </div>
                    </>
                )}
            </div>
        );
    } else if (content === "evaluation") {
        return (
            <div className="pt-5">
                {language === "en" ? (
                    <>
                        <div
                            style={{ display: "flex", alignItems: "baseline" }}
                        >
                            <p style={{ fontSize: "2.5vw", color: "red" }}>
                                {translationChTitleMap[subContent]}
                            </p>
                        </div>
                        <div
                            style={{
                                lineHeight: "2",
                                color: "grey",
                                paddingTop: "4vh",
                            }}
                        >
                            {translationEnSubTitleMap[subContent]}
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            style={{ display: "flex", alignItems: "baseline" }}
                        >
                            <p style={{ fontSize: "2.5vw", color: "red" }}>
                                {translationChTitleMap[subContent]}
                            </p>
                            <p style={{ paddingLeft: "3vw", color: "grey" }}>
                                {translationEnTitleWithoutNumberMap[subContent]}
                            </p>
                        </div>
                        <div
                            style={{
                                lineHeight: "2",
                                color: "grey",
                                paddingTop: "4vh",
                            }}
                        >
                            {translationChSubTitleMap[subContent].map(
                                (text, index) => (
                                    <p key={index}>{TabReplace(text)}</p>
                                )
                            )}
                        </div>
                        <div
                            style={{
                                lineHeight: "2",
                                color: "grey",
                                paddingTop: "4vh",
                            }}
                        >
                            {translationEnSubTitleMap[subContent].map(
                                (text, index) => (
                                    <p key={index}>{TabReplace(text)}</p>
                                )
                            )}
                        </div>
                    </>
                )}
            </div>
        );
    } else if (content === "remider") {
        return (
            <div className="pt-5">
                {language === "en" ? (
                    <>
                        <div
                            style={{ display: "flex", alignItems: "baseline" }}
                        >
                            <p style={{ fontSize: "2.5vw", color: "red" }}>
                                {data[language].remiderTitle}
                            </p>
                        </div>
                        <div
                            style={{
                                lineHeight: "2",
                                color: "grey",
                                paddingTop: "4vh",
                            }}
                        >
                            {data[language].remiderContent}
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            style={{ display: "flex", alignItems: "baseline" }}
                        >
                            <p style={{ fontSize: "2.5vw", color: "red" }}>
                                {data[language].remiderTitle}
                            </p>
                            <p style={{ paddingLeft: "3vw", color: "grey" }}>
                                {data["en"].remiderTitle}
                            </p>
                        </div>
                        <div
                            style={{
                                lineHeight: "2",
                                color: "grey",
                                paddingTop: "4vh",
                            }}
                        >
                            {data[language].remiderContent.map(
                                (text, index) => (
                                    <p key={index}>{TabReplace(text)}</p>
                                )
                            )}
                        </div>
                        <div
                            style={{
                                lineHeight: "2",
                                color: "grey",
                                paddingTop: "4vh",
                            }}
                        >
                            {data["en"].remiderContent.map((text, index) => (
                                <p key={index}>{TabReplace(text)}</p>
                            ))}
                        </div>
                    </>
                )}
            </div>
        );
    } else if (content === "actionStep") {
        return <SensoryIntegrationSteps jsonFileName={jsonFileName} />;
    } else if (content === "result") {
        return (
            <div className="pt-5">
                {language === "en" ? (
                    <>
                        <div
                            style={{ display: "flex", alignItems: "baseline" }}
                        >
                            <p style={{ fontSize: "2.5vw", color: "red" }}>
                                {data[language].educationTheoryTitle}
                            </p>
                            <p style={{ paddingLeft: "3vw", color: "grey" }}>
                                {data["en"].educationTheoryTitle}
                            </p>
                        </div>
                        <div
                            style={{
                                lineHeight: "2",
                                color: "grey",
                                paddingTop: "4vh",
                            }}
                        >
                            {data[language].educationTheoryContent}
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            style={{ display: "flex", alignItems: "baseline" }}
                        >
                            <p style={{ fontSize: "2.5vw", color: "red" }}>
                                {data[language].educationTheoryTitle}
                            </p>
                            <p style={{ paddingLeft: "3vw", color: "grey" }}>
                                {data["en"].educationTheoryTitle}
                            </p>
                        </div>
                        <div
                            style={{
                                lineHeight: "2",
                                color: "grey",
                                paddingTop: "4vh",
                            }}
                        >
                            {data[language].educationTheoryContent}
                        </div>
                        <div
                            style={{
                                lineHeight: "2",
                                color: "grey",
                                paddingTop: "4vh",
                            }}
                        >
                            {data["en"].educationTheoryContent}
                        </div>
                    </>
                )}
            </div>
        );
    } else if (content === "track") {
        return <ClickableGrid jsonFileName={jsonFileName} />;
    }
}

export default SensoryIntegrationRightRegion;
