import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../css/MultipleGridContainer.css";
import { useDrop } from "react-dnd";

import "../css/MultipleGridContainer.css";

function GridCell({ index, gridData, onDrop, onRemove }) {
    const [{ isOver }, dropRef] = useDrop({
        accept: "video",
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
        drop: (item, monitor) => {
            const droppedData = monitor.getItem();
            if (droppedData && droppedData.video) {
                const droppedVideo = JSON.parse(droppedData.video);
                onDrop(index, droppedVideo);
            }
        },
    });

    const handleRemove = () => {
        onRemove(index);
    };

    return (
        <div ref={dropRef} className="grid-drop-area">
            {gridData[index] ? (
                <div className={`grid-content ${isOver ? "is-over" : ""}`}>
                    {gridData[index].name}
                    <button onClick={handleRemove} className="remove-button">
                        ×
                    </button>
                </div>
            ) : (
                <div className={`empty-cell ${isOver ? "is-over" : ""}`}>
                    {`Cell ${index + 1}`}
                </div>
            )}
        </div>
    );
}

function MultipleGridContainer({
    gridData,
    onDrop,
    onRemove,
    numr = 3,
    numc = 3,
}) {
    return (
        <Container fluid className="multiple_grid_container_background_primary">
            {Array.from({ length: numr }).map((_, rowIndex) => (
                <Row key={rowIndex}>
                    {Array.from({ length: numc }).map((_, colIndex) => (
                        <Col key={colIndex}>
                            <GridCell
                                index={rowIndex * numc + colIndex}
                                gridData={gridData}
                                onDrop={onDrop}
                                onRemove={onRemove}
                            />
                        </Col>
                    ))}
                </Row>
            ))}
        </Container>
    );
}

export default MultipleGridContainer;
