import React, { useState, useContext, useEffect } from "react";
import LanguageContext from "../../languageSwitch/LanguageContext";

import translations from "../../translations.json";
import "../css/ClickableGrid.css";

function ClickableGrid({ jsonFileName }) {
    const { language } = useContext(LanguageContext);
    const [selectedGrid, setSelectedGrid] = useState(null);
    const [data, setData] = useState(null);

    useEffect(() => {
        import(`../../${jsonFileName}.json`)
            .then((response) => {
                setData(response.default);
            })
            .catch((error) => {
                console.error("Error loading json file:", error);
                setData(null);
            });
    }, [jsonFileName]);

    if (!data) {
        return <div>Loading...</div>; // 或者可以返回任何其他的加载指示器
    }

    const handleGridClick = (gridId) => {
        setSelectedGrid(gridId);
    };

    const handleBackClick = () => {
        setSelectedGrid(null);
    };

    const grids = Array(16)
        .fill(null)
        .map((_, index) => (
            <div
                key={index}
                className="clickable_grid_cell"
                onClick={() => handleGridClick(index)}
            />
        ));

    const selectedGridContent = {
        0: data[language]["resultGridRowCol_00"],
        1: data[language]["resultGridRowCol_01"],
        2: data[language]["resultGridRowCol_02"],
        3: data[language]["resultGridRowCol_03"],
        4: data[language]["resultGridRowCol_10"],
        5: data[language]["resultGridRowCol_11"],
        6: data[language]["resultGridRowCol_12"],
        7: data[language]["resultGridRowCol_13"],
        8: data[language]["resultGridRowCol_20"],
        9: data[language]["resultGridRowCol_21"],
        10: data[language]["resultGridRowCol_22"],
        11: data[language]["resultGridRowCol_23"],
        12: data[language]["resultGridRowCol_30"],
        13: data[language]["resultGridRowCol_31"],
        14: data[language]["resultGridRowCol_32"],
        15: data[language]["resultGridRowCol_33"],
    };

    const headerLabels = [
        data[language]["resultGridColName1"],
        data[language]["resultGridColName2"],
        data[language]["resultGridColName3"],
        data[language]["resultGridColName4"],
    ];
    const sideLabels = [
        data[language]["resultGridRowName1"],
        data[language]["resultGridRowName2"],
        data[language]["resultGridRowName3"],
        data[language]["resultGridRowName4"],
    ];

    if (selectedGrid !== null) {
        return (
            <div className="pt-5">
                {selectedGridContent[selectedGrid]}
                <button
                    className="clickable_return_button"
                    onClick={handleBackClick}
                >
                    {translations[language]["back"]}
                </button>
            </div>
        );
    }

    return (
        <div className="clickable_grid_background pt-5">
            <div />
            {headerLabels.map((label, idx) => (
                <div key={idx} className="clickable_grid_header">
                    {label}
                </div>
            ))}
            {sideLabels.map((label, idx) => (
                <React.Fragment key={idx}>
                    <div className="clickable_grid_side_label">{label}</div>
                    {grids.slice(idx * 4, idx * 4 + 4)}
                </React.Fragment>
            ))}
        </div>
    );
}

export default ClickableGrid;
