import React from "react";
import "../css/SensorySubButton.css";

function SensorySubButton({ onClick, label, selected }) {
    const buttonStyle = selected
        ? "sensory_sub_button_selected_primary"
        : "sensory_sub_button_primary";

    return (
        <button
            onClick={onClick}
            className={buttonStyle}
            style={{ marginBottom: "2vh" }}
        >
            {label}
        </button>
    );
}

export default SensorySubButton;
