import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CourseButton from "../button/components/CourseButton";
import TextToSpeech from "./components/TextToSpeech";

import "./css/FunctionPage.css";

function FunctionPage() {
    const [selectedLeftButton, setSelectedLeftButton] = useState("btn1 L");
    const [selectedRightButton, setSelectedRightButton] = useState("btn1 R");

    const functionTypeLeft = ["btn1 L", "btn2 L", "btn3 L", "btn4 L"];

    const functionTypeRight = ["btn1 R", "btn2 R", "btn3 R", "btn4 R"];

    const handleLeftButtonClick = (button) => {
        setSelectedLeftButton(button);
        setSelectedRightButton("btn1 R");
    };

    const handleRightButtonClick = (button) => {
        setSelectedLeftButton("btn1 L");
        setSelectedRightButton(button);
    };

    return (
        <Container>
            <Row>
                <Col>
                    {functionTypeLeft.map((button, index) => (
                        <CourseButton
                            key={index}
                            onClick={() => handleLeftButtonClick(button)}
                            selected={button === selectedLeftButton}
                            label={functionTypeLeft[index]}
                        />
                    ))}
                </Col>
                <Col>
                    <TextToSpeech />
                </Col>
                <Col>
                    {functionTypeRight.map((button, index) => (
                        <CourseButton
                            key={index}
                            onClick={() => handleRightButtonClick(button)}
                            selected={button === selectedRightButton}
                            label={functionTypeRight[index]}
                        />
                    ))}
                </Col>
            </Row>
        </Container>
    );
}

export default FunctionPage;
