import Router from "./Router";
import LanguageProvider from "./languageSwitch/LanguageProvider";

function App(){
    return (
        <LanguageProvider>
            <Router/>
        </LanguageProvider>
    );
}

export default App;