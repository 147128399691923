import React, { useContext, useState, useEffect } from "react";
import TabReplace from "./TabReplace";
import LanguageContext from "../../languageSwitch/LanguageContext";

import translations from "../../translations.json";
import "../css/SensoryIntegrationSteps.css";

function SensoryIntegrationSteps({ jsonFileName }) {
    const { language } = useContext(LanguageContext);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = 4; // 因为有四个步骤

    // const goToPrevPage = () => {
    //     setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    // };

    const [data, setData] = useState(null);

    useEffect(() => {
        import(`../../${jsonFileName}.json`)
            .then((response) => {
                setData(response.default);
            })
            .catch((error) => {
                console.error("Error loading json file:", error);
                setData(null);
            });
    }, [jsonFileName]);

    if (!data) {
        return <div>Loading...</div>; // 或者可以返回任何其他的加载指示器
    }

    const goToNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const goToFirstPage = () => {
        setCurrentPage(1);
    };

    const stepsContent = [
        {
            titleKey: "actionStepTitle1",
            contentKey: "actionStepContent1",
        },
        {
            titleKey: "actionStepTitle2",
            contentKey: "actionStepContent2",
        },
        {
            titleKey: "actionStepTitle3",
            contentKey: "actionStepContent3",
        },
        {
            titleKey: "actionStepTitle4",
            contentKey: "actionStepContent4",
        },
    ];

    return (
        <div>
            <div className="sensory_integration_steps_container pt-5">
                {stepsContent.map((step, index) => (
                    <div
                        key={index}
                        className="sensory_integration_steps_item"
                        style={{
                            transform: `translateX(-${
                                (currentPage - 1) * 100
                            }%)`,
                        }}
                    >
                        {language === "en" ? (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "baseline",
                                    }}
                                >
                                    <p
                                        style={{
                                            fontSize: "2.5vw",
                                            color: "red",
                                        }}
                                    >
                                        {data[language][step.titleKey]}
                                    </p>
                                </div>
                                <div
                                    style={{
                                        lineHeight: "2",
                                        color: "grey",
                                        paddingTop: "4vh",
                                    }}
                                >
                                    {data[language][step.contentKey].map(
                                        (text, index) => (
                                            <p key={index}>
                                                {TabReplace(text)}
                                            </p>
                                        )
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "baseline",
                                    }}
                                >
                                    <p
                                        style={{
                                            fontSize: "2.5vw",
                                            color: "red",
                                        }}
                                    >
                                        {data[language][step.titleKey]}
                                    </p>
                                    <p
                                        style={{
                                            paddingLeft: "3vw",
                                            color: "grey",
                                        }}
                                    >
                                        {data["en"][step.titleKey]}
                                    </p>
                                </div>
                                <div
                                    style={{
                                        lineHeight: "2",
                                        color: "grey",
                                        paddingTop: "4vh",
                                    }}
                                >
                                    {data[language][step.contentKey].map(
                                        (text, index) => (
                                            <p key={index}>
                                                {TabReplace(text)}
                                            </p>
                                        )
                                    )}
                                </div>
                                <div
                                    style={{
                                        lineHeight: "2",
                                        color: "grey",
                                        paddingTop: "4vh",
                                    }}
                                >
                                    {data["en"][step.contentKey].map(
                                        (text, index) => (
                                            <p key={index}>
                                                {TabReplace(text)}
                                            </p>
                                        )
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>
            <div style={{ textAlign: "right" }}>
                {/* 下一步按钮 */}
                {currentPage < totalPages && (
                    <button onClick={goToNextPage}>
                        {translations[language]["nextStep"]}
                    </button>
                )}

                {/* 回到第一步按钮 */}
                {currentPage === totalPages && (
                    <button onClick={goToFirstPage}>
                        {translations[language]["backInit"]}
                    </button>
                )}
            </div>
        </div>
    );
}

export default SensoryIntegrationSteps;
