import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LanguageContext from "../languageSwitch/LanguageContext";
import SensoryButton from "../button/components/SensoryButton";
import SensorySubButton from "../button/components/SensorySubButton";
import SensoryIntegrationLeftTopRegion from "./components/SensoryIntegrationLeftTopRegion";
import SensoryIntegrationRightRegion from "./components/SensoryIntegrationRightRegion";
import { useLocation } from "react-router-dom";

import translations from "../translations.json";
import "./css/SensoryIntegrationPage.css";

function SensoryIntegrationPage({ jsonFileName }) {
    const location = useLocation();
    //     const [layout, setLayout] = useState("default");
    //     const [subLayout, setSubLayout] = useState("default");
    const [subButtonsVisible, setSubButtonsVisible] = useState(false);
    const [selectedSensoryButton, setSelectedSensoryButton] = useState(null);
    const [selectedSubButton, setSelectedSubButton] = useState(
        "evaluationLeftTopBtnName"
    );
    const [topRegionContent, setTopRegionContent] = useState("default");
    const [data, setData] = useState(null);

    const { language } = useContext(LanguageContext);

    useEffect(() => {
        import(`../${jsonFileName}.json`)
            .then((response) => {
                setData(response.default);
            })
            .catch((error) => {
                console.error("Error loading json file:", error);
                setData(null);
            });
    }, [jsonFileName]);

    useEffect(() => {
        // 當進入該頁面時，移除背景圖片
        document.body.style.backgroundImage = "none";

        // 當離開該頁面時，清理函數將被調用，恢復背景圖片
        return () => {
            document.body.style.backgroundImage = 'url("/bgImg.png")';
        };
    }, [location.pathname]); // 依賴於 location.pathname

    if (!data) {
        return <div>Loading...</div>; // 或者可以返回任何其他的加载指示器
    }

    const sensoryButtons = [
        "unit",
        "target",
        "expert",
        "evaluation",
        "remider",
        "actionStep",
        "result",
        "track",
    ];
    const subButtons = [
        "evaluationLeftTopBtnName",
        "evaluationLeftBottomBtnName",
        "evaluationRightTopBtnName",
        "evaluationRightBottomBtnName",
    ];

    const handleButtonClick = (button) => {
        // setLayout("layout");
        // setSubLayout("default");
        setSubButtonsVisible(false);
        setSelectedSensoryButton(button);
        setTopRegionContent(button);

        // 在這裡根據按鈕執行您的特定邏輯
        switch (button) {
            case "unit":
                setSubButtonsVisible(false);
                break;
            case "target":
                setSubButtonsVisible(false);
                break;
            case "expert":
                setSubButtonsVisible(false);
                break;
            case "evaluation":
                setSubButtonsVisible(true);
                setSelectedSubButton("evaluationLeftTopBtnName");
                break;
            case "remider":
                setSubButtonsVisible(false);
                break;
            case "actionStep":
                setSubButtonsVisible(false);
                break;
            case "result":
                setSubButtonsVisible(false);
                break;
            case "track":
                setSubButtonsVisible(false);
                break;
            default:
                break;
        }
    };

    const handleSubButtonClick = (button) => {
        // setSubLayout("subLayout");
        setSelectedSubButton(button);

        switch (button) {
            case "evaluationLeftTopBtnName":
                setSelectedSubButton("evaluationLeftTopBtnName");
                break;
            case "evaluationRightTopBtnName":
                setSelectedSubButton("evaluationRightTopBtnName");
                break;
            case "evaluationLeftBottomBtnName":
                setSelectedSubButton("evaluationLeftBottomBtnName");
                break;
            case "evaluationRightBottomBtnName":
                setSelectedSubButton("evaluationRightBottomBtnName");
                break;
            default:
                break;
        }
    };

    const resetContentToDefault = () => {
        setTopRegionContent("default");
        setSelectedSensoryButton(null);
        setSubButtonsVisible(false);
        setSelectedSubButton("evaluationLeftTopBtnName");
    };

    return (
        <Container fluid className="p-4">
            <Row>
                <Col className="sensory_integration_left" md={5} sm={12}>
                    <div className="sensory_integration_left_top">
                        <SensoryIntegrationLeftTopRegion
                            content={topRegionContent}
                            onCastleClick={resetContentToDefault}
                            jsonFileName={jsonFileName}
                        />
                    </div>
                    <div className="sensory_integration_left_bottom pt-5">
                        <div className="row">
                            <div className="col-6">
                                {sensoryButtons.slice(0, 4).map((button) => (
                                    <SensoryButton
                                        key={button}
                                        onClick={() =>
                                            handleButtonClick(button)
                                        }
                                        selected={
                                            button === selectedSensoryButton
                                        }
                                        label={translations[language][button]}
                                    />
                                ))}
                            </div>
                            <div className="col-6">
                                {sensoryButtons.slice(4, 8).map((button) => (
                                    <SensoryButton
                                        key={button}
                                        onClick={() =>
                                            handleButtonClick(button)
                                        }
                                        selected={
                                            button === selectedSensoryButton
                                        }
                                        label={translations[language][button]}
                                    />
                                ))}
                            </div>
                        </div>
                        <div>
                            {subButtonsVisible && (
                                <div
                                    className="row"
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        marginLeft: "1vh",
                                    }}
                                >
                                    <div className="col-2">
                                        {subButtons
                                            .slice(0, 2)
                                            .map((button) => (
                                                <SensorySubButton
                                                    key={button}
                                                    onClick={() =>
                                                        handleSubButtonClick(
                                                            button
                                                        )
                                                    }
                                                    selected={
                                                        button ===
                                                        selectedSubButton
                                                    }
                                                    label={
                                                        data[language][button]
                                                    }
                                                />
                                            ))}
                                    </div>
                                    <div className="col-2">
                                        {subButtons
                                            .slice(2, 4)
                                            .map((button) => (
                                                <SensorySubButton
                                                    key={button}
                                                    onClick={() =>
                                                        handleSubButtonClick(
                                                            button
                                                        )
                                                    }
                                                    selected={
                                                        button ===
                                                        selectedSubButton
                                                    }
                                                    label={
                                                        data[language][button]
                                                    }
                                                />
                                            ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Col>
                <Col className="sensory_integration_right pt-5" md={7} sm={12}>
                    <SensoryIntegrationRightRegion
                        content={topRegionContent}
                        subContent={selectedSubButton}
                        jsonFileName={jsonFileName}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export default SensoryIntegrationPage;
