import React, { useEffect, useState } from "react";

function PanoViewer() {
    const [isUserInteracted, setUserInteracted] = useState(false);

    useEffect(() => {
        if (!isUserInteracted) return;

        let scriptPlayerLoaded = false;
        let scriptSkinLoaded = false;

        const scriptPlayer = document.createElement("script");
        scriptPlayer.src = "/output-1/pano2vr_player.js";
        scriptPlayer.onload = () => {
            console.log("Pano2VR player script loaded.");
            scriptPlayerLoaded = true;
            if (scriptSkinLoaded) {
                initPanoViewer();
            }
        };
        document.body.appendChild(scriptPlayer);

        const scriptSkin = document.createElement("script");
        scriptSkin.src = "/output-1/skin.js";
        scriptSkin.onload = () => {
            console.log("Skin script loaded.");
            scriptSkinLoaded = true;
            if (scriptPlayerLoaded) {
                initPanoViewer();
            }
        };
        document.body.appendChild(scriptSkin);

        function initPanoViewer() {
            const pano = new window.pano2vrPlayer("container");
            new window.pano2vrSkin(pano);
            pano.readConfigUrlAsync("/output-1/pano.xml");
        }

        return () => {
            console.log("清理 Pano2VR 脚本");
            document.body.removeChild(scriptPlayer);
            document.body.removeChild(scriptSkin);
        };
    }, [isUserInteracted]);

    return (
        <div>
            <h1>Pano2VR Viewer</h1>
            {!isUserInteracted ? (
                <button onClick={() => setUserInteracted(true)}>
                    click to start
                </button>
            ) : (
                <div id="container" style={{ width: "100%", height: "600px" }}>
                    {/* Pano2VR 将在这里渲染全景图 */}
                </div>
            )}
        </div>
    );
}

export default PanoViewer;
