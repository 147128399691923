import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import HomePage from "./pages/HomePage";
import TestPage from "./pages/TestPage";
import VideoListPage from "./pages/VideoListPage";
import VideoPlayer from "./pages/components/VideoPlayer";
import MainPage from "./pages/MainPage";
import PlatformPage from "./pages/PlatformPage";
import SingleVideoPage from "./pages/SingleVideoPage";
import CourseTestPage from "./pages/CourseTestPage";
import AcademicTheoryPage from "./pages/AcademicTheoryPage";
import LogInPage from "./pages/LogInPage";
import MultipleGridPage from "./pages/MultipleGridPage";
import SensoryIntegrationPage from "./pages/SensoryIntegrationPage";
import DaySchedule from "./pages/DaySchedule";
import TextToSpeech from "./pages/components/TextToSpeech";
import FunctionPage from "./pages/FunctionPage";
import PanoViewer from "./pages/PanoViewer";
// import KAKALeader from "./button/components/KAKALeader";

function Router() {
    return (
        <BrowserRouter>
            <DndProvider backend={HTML5Backend}>
                <Routes>
                    <Route path="/" element={<MainPage />} />
                    <Route path="/pano" element={<PanoViewer />} />
                    <Route path="/test" element={<TestPage />} />
                    <Route path="/home" element={<HomePage />} />
                    <Route path="/login" element={<LogInPage />} />
                    <Route path="/register" element={"Register"} />
                    <Route path="/videolist" element={<VideoListPage />} />
                    <Route path="/video" element={<VideoPlayer />} />
                    <Route path="/platform" element={<PlatformPage />} />
                    <Route
                        path="/primary/unitOne"
                        element={
                            <SingleVideoPage jsonFileName={"primaryUnitOne"} />
                        }
                    />
                    <Route
                        path="/primary/unitTwo"
                        element={
                            <SingleVideoPage jsonFileName={"primaryUnitTwo"} />
                        }
                    />
                    <Route
                        path="/primary/unitThree"
                        element={
                            <SingleVideoPage
                                jsonFileName={"primaryUnitThree"}
                            />
                        }
                    />
                    <Route
                        path="/primary/unitFour"
                        element={
                            <SingleVideoPage jsonFileName={"primaryUnitFour"} />
                        }
                    />
                    <Route
                        path="/junior/unitOne"
                        element={
                            <SingleVideoPage jsonFileName={"juniorUnitOne"} />
                        }
                    />
                    <Route
                        path="/junior/unitTwo"
                        element={
                            <SingleVideoPage jsonFileName={"juniorUnitTwo"} />
                        }
                    />
                    <Route
                        path="/junior/unitThree"
                        element={
                            <SingleVideoPage jsonFileName={"juniorUnitThree"} />
                        }
                    />
                    <Route
                        path="/junior/unitFour"
                        element={
                            <SingleVideoPage jsonFileName={"juniorUnitFour"} />
                        }
                    />
                    <Route
                        path="/senior/unitOne"
                        element={
                            <SingleVideoPage jsonFileName={"seniorUnitOne"} />
                        }
                    />
                    <Route
                        path="/senior/unitTwo"
                        element={
                            <SingleVideoPage jsonFileName={"seniorUnitTwo"} />
                        }
                    />
                    <Route
                        path="/senior/unitThree"
                        element={
                            <SingleVideoPage jsonFileName={"seniorUnitThree"} />
                        }
                    />
                    <Route
                        path="/senior/unitFour"
                        element={
                            <SingleVideoPage jsonFileName={"seniorUnitFour"} />
                        }
                    />
                    <Route
                        path="/preschool/unitOne"
                        element={
                            <SingleVideoPage
                                jsonFileName={"preschoolUnitOne"}
                            />
                        }
                    />
                    <Route
                        path="/preschool/unitTwo"
                        element={
                            <SingleVideoPage
                                jsonFileName={"preschoolUnitTwo"}
                            />
                        }
                    />
                    <Route
                        path="/preschool/unitThree"
                        element={
                            <SingleVideoPage
                                jsonFileName={"preschoolUnitThree"}
                            />
                        }
                    />
                    <Route
                        path="/preschool/unitFour"
                        element={
                            <SingleVideoPage
                                jsonFileName={"preschoolUnitFour"}
                            />
                        }
                    />
                    <Route
                        path="/preschool/unitFive"
                        element={
                            <SingleVideoPage
                                jsonFileName={"preschoolUnitFive"}
                            />
                        }
                    />
                    <Route
                        path="/preschool/unitSix"
                        element={
                            <SingleVideoPage
                                jsonFileName={"preschoolUnitSix"}
                            />
                        }
                    />
                    <Route path="/courseTest" element={<CourseTestPage />} />
                    <Route
                        path="/academicTheory"
                        element={<AcademicTheoryPage />}
                    />
                    <Route
                        path="/multipleGrid"
                        element={<MultipleGridPage />}
                    />
                    <Route
                        path="/sensory/main"
                        element={
                            <SensoryIntegrationPage jsonFileName={"castle"} />
                        }
                    />
                    <Route path="/daySchedule" element={<DaySchedule />} />
                    <Route path="/textToSpeech" element={<TextToSpeech />} />
                    <Route path="/function" element={<FunctionPage />} />
                </Routes>
            </DndProvider>
        </BrowserRouter>
    );
}

export default Router;
